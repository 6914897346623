$env: production;
@import '@/styles/_component-base.styles.scss';

$transition-content-close: all 300ms cubic-bezier(0, 0, 0.25, 1) !default;
$transition-content-open: all 300ms cubic-bezier(0.25, 0, 1, 1), opacity 300ms cubic-bezier(0.25, 0, 1, 1) 200ms !default;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pictureWrapper {
    height: 100%;

}

.header {
    font-family: $fontFamily-serif;
    font-size: set-fontSize(28);
    font-weight: 300;
    text-align: center;
    color: color(text, headingDark);
    margin-bottom: spacer(2xl);

    @include breakpoint(md) {
        text-align: center;
    }
}

.tab {
    border-radius: 9999px;
    letter-spacing: spacer(2xs);
    font-weight: 300;
    font-size: set-fontSize(16);
    padding: spacer(xs) spacer(md);
    margin: spacer(base);
    color: color(text, recede);

    &:is(:hover, :focus) {
        color: color(text, base);
        background-color: color(contrast, 5);
    }

    &[aria-selected="true"] {
        color: white;
        background-color: color(accent, green);
    }
}

.constructionContainer {
    display: flex;
    position: relative;
    width: 100%;

    &[hidden] {
        display: none;
    }
}

.constructionContent {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
}

.constructionTab {
    color: color(text, base);
    font-size: set-fontSize(20);
    font-weight: 300;
    padding: spacer(sm);
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 0px;
    min-width: 100%;

    &:is(:hover, :focus) {
        color: color(text, base);
    }

    &[aria-selected="true"] {
        color: color(text, base);
        font-weight: normal;
        padding-bottom: 0;
    }
}

.constructionStepText {
    margin-left: spacer(sm);
    display: flex;
    align-items: center;
    text-align: left;
    z-index: 1;
    text-wrap: wrap;
}

.constructionButton {
    cursor: pointer;
}

.constructionDescription {
    display: block;
    margin: spacer(sm) spacer(3xl) 0;
    white-space: normal;
    text-align: left;
    overflow: hidden;

    &:not(:last-child) {
        max-height: 0px;
    }

    &:not([hidden]) {
        animation: reveal 0 ease 0.01s;
        overflow: visible;
        visibility: visible;
        opacity: 1;
        transition: $transition-content-open;
    }

    &[hidden] {
        opacity: 0;
        visibility: hidden;
        transition: $transition-content-close;
    }

    ul > li {
        &:before {
        content: "\2022";
        font-weight: normal;
        margin-right: spacer(xs);
        }
    }
}

.constructionStep {
    display: flex;
    flex-direction: row;
}

.constructionStepIcon {
    width: 24px;
    height: 24px;
    font-size: set-fontSize(14);

    @include breakpoint(md) {
        width: 34px;
        height: 34px;
        font-size: set-fontSize(20);
    }

    font-weight: 300;
    border-radius: 9999px;
    border: solid 1px color(contrast, 3);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: color(contrast, white);
    color: color(text, base);

    :is(:hover, :focus) > & {
        color: color(text, base);
        background-color: color(contrast, 5);
    }

    [aria-selected="true"] & {
        border: none;
        color: color(contrast, white);
    }
}

/*
    TODO: pull these into util files and standardize with our u-bgColor styles, maybe as u-bgColorAriaSelected--accentBlue
    OR to make these styles specific to the mattress layer circles
*/
.u-bgColor--bgContrast2[aria-selected="true"] .constructionStepIcon  {
    background-color: color(darkContrast, 2);
}
.u-bgColor--bgAccentGreen[aria-selected="true"] .constructionStepIcon {
    background-color: color(accent, green);
}
.u-bgColor--bgAccentBlue[aria-selected="true"] .constructionStepIcon {
    background-color: color(accent, blue);
}
.u-bgColor--bgActionBase[aria-selected="true"] .constructionStepIcon {
    background-color: color(action, base);
}

.constructionWrapper {
    flex-grow: 1.5;
    flex-basis: 0;
    height: 100%;
}

.constructionLineOverlay {
    position: absolute;
    pointer-events: none;
}

.image {
    height: auto;
    width: 100%;
}

.imageFooter {
    display: block;
    background-color: color(contrast, 2);
    margin-bottom: spacer(2dot5xl);

    @include breakpoint(md) {
        margin-bottom: spacer(4xl);
    }

    @include breakpoint(lg) {
        margin-bottom: 0;
    }

    p {
        color: color(text, base);
        font-size: set-fontSize(14);
        font-weight: 200;
        line-height: 1.14;
        padding: spacer(sm) spacer(md);
        text-align: center;
    }
}

.sliderItem {
    max-width: 100%;
    height: max-content;
    margin: 0 auto;
    padding: 0 spacer(base);
}

.sliderTitle {
    text-align: center;
    font-weight: 400;
    font-size: set-fontSize(20);
    padding-bottom: spacer(xl);
    margin: 0 auto;

    @include breakpoint(lg) {
        padding-bottom: spacer(sm);
    }
}

.sliderContent {
    max-width: 230px;
    margin: 0 auto;
    text-align: left;

    ul > li {
        &:before {
            content: "\2022";
            font-weight: normal;
            margin-right: spacer(xs);
        }
    }

    @include breakpoint(md) {
        margin: 0 auto;
        max-width: 420px;
        padding-left: spacer(base);
    }

    @include breakpoint(lg) {
        max-width: 100%;
    }
}

.sliderChevron {
    transform-origin: center;
    transform: scale(1.25, 2);
    stroke: color(darkContrast, 3);
}

@keyframes reveal {
    from {
        overflow: hidden;
    }
    to {
        overflow: visible;
    }
}

.mattressLayerSlider {
    width: 100%;

    & > .slider-button {
        > .slider-button-icon {
            fill: color(darkContrast, 3);
            transform-origin: center;
            transform: scale(1.25, 2);
        }

        &[disabled] > .slider-button-icon {
            fill: color(contrast, 4);
        }
    }

    & > .previous {
        left: 10px;
    }

    & > .next {
        right: 10px;
    }

    & > .slider-page-dots {
        display: flex;
        align-items: center;
        justify-content: center;

        & > .dot {
            margin: 0;
            border-radius: 0;
            width: spacer(4xl);
            border: none;
            border-bottom: solid color(contrast, 3) 1px;
            background: none;

            &.is-selected {
                border-bottom: solid color(darkContrast, 3) 1px;
                background: none;
            }
        }
    }

    & > button {
        display: inline-block;
    }
}
