$env: production;
@import '@/styles/_component-base.styles.scss';

.section {
    background: linear-gradient(#000, #453F39);
    padding: spacer(3xl) spacer(md);

    @include breakpoint(md){
        padding: spacer(3xl) spacer(3dot75xl);
    }

    @include breakpoint(lg) {
        padding: spacer(4xl) spacer(10xl);
    }
}
    
.header {
    font-family: $fontFamily-serif;
    font-size: set-fontSize(24);
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
    color: color(contrast, white);
    margin-bottom: spacer(lg);

    @include breakpoint(md){
        margin-bottom: spacer(xs);
    }

    @include breakpoint(lg) {
        font-size: set-fontSize(32);
    }
}

.iconsWrapper {
    justify-content: space-evenly;
}

.icon {
    width: spacer(3dot75xl);
    height: spacer(3dot75xl);
    color: color(action, base);

}

.iconSubheading {
    width: 16px;
    height: 13px;
    border: 1px solid color(contrast, white);
    color: color(contrast, white);
    margin-right: spacer(xs);
}

.text {
    font-weight: 400;
    line-height: 1.5;
    color: color(text, hover);
}

.highlighted {
    color: color(contrast, white)
}

.subheading {
    font-family: $fontFamily-serif;
    font-weight: 400;
    font-size: set-fontSize(20);
    color: color(contrast, white);
    cursor: pointer;
}

.video {
    margin-top: spacer(xl);
}
