$env: production;
@import '@/styles/_component-base.styles.scss';

.wrapper {
    text-align: left;
    overflow: visible;

    // Theme-specific overrides
    
    &.contrast1 {
        background-color: color(contrast, 1);
    }

    &.contrast2 {
        background-color: color(contrast, 2);

        .callout {
            background-color: color(contrast, white);
        }
    }
}

.accordionSet {
    border-top: 1px solid color(contrast, 3);
    margin-top: 0;
}

.accordion {
    padding: 0;
    border-bottom: 1px solid color(contrast, 3);
    margin-bottom: 0;
    background-color: unset;
}

.accordionTitleContainer {
    padding: spacer(lg) 0;

    @include breakpoint(lg) {
        padding: spacer(2xl) 0;
        :global(.t-heading3) {
            font-size: set-fontSize(24);
        }
    }
}

.accordionContentContainer {
    display: flex;
    flex-direction: column;
    gap: spacer(2xl);
    padding-left: 0;
    padding-right: 0;

    @include breakpoint(lg) {
        padding-bottom: spacer(2xl);
        flex-direction: row;
    }

    > div {
        flex-grow: 1;
        width: 100%;
    }

    h4 {
        @extend .t-heading4;

        .light {
            font-weight: lighter;
        }
    }

    ul {
        margin-left: spacer(base);
        margin-bottom: 0;
    }

    li {
        line-height: spacer(lg);
        margin-bottom: spacer(sm);
    }

    p {
        margin-bottom: spacer(xs);
    }

    strong {
        @extend .t-base;
    }

    small {
        @extend .t-bodySm;
    }

    em {
        font-style: italic;
    }
}
@include breakpoint(lg) {
    .accordionTitleContainerTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: spacer(xl);

        div {
            flex: 1;
        }
    }
}

.firmnessSprite {
    width: 96px;
    height: 96px;
}

.bulletSprite {
    width: 16px;
    height: 16px;
    margin-right: spacer(xs);
    vertical-align: middle;

    &.green {
        color: color(accent, green);
    }
}

// Simulates bullets with SVG sprite
ul.compatibleList {
    --text-margin: 1.5rem;
    list-style-type: none;
    margin-left: var(--text-margin);

    .compatibilityTooltip {
        .tooltip {
            width: 20px;
            top: 4px;
            margin-left: spacer(3xs);
        }
        
        .inlineTooltip {
            display: inline;
            top: spacer(xs);
            margin-left: spacer(2xs);
        
            button {
                position: relative;
            }
        }
        
        .tooltipContent {
            top: spacer(2xl);
            width: 250px;
            max-width: none;
        }
    }

    .bulletSprite {
        position: absolute;
        top: .3rem;
        left: calc(var(--text-margin) * -1);
    }
}

.featuresWrapper {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(md) {
        justify-content: center;
    }

    @include breakpoint(lg) {
        margin-bottom: spacer(sm);
    }

    > * {
        width: 50%;
        display: flex;
        align-items: center;
        line-height: 1.25rem;
        margin-bottom: spacer(base);

        // 4-across for tablet and desktop
        @include breakpoint(md) {
            width: 25%;
        }
    }

    .featureSprite {
        flex-shrink: 0;
        color: color(action, base);

        // This calc accounts for the difference between the size of the element and the viewport for these svgs.
        // This formula allows for setting the desired dimenion with the var alone, in this case 48px.
        --desired-dimension: 48px;
        --calculated-dimension: calc(var(--desired-dimension) * 100 / 71);
        width: var(--calculated-dimension);
        height: var(--calculated-dimension);

        @include breakpoint(lg) {
            // Just override the variable, and the calc function picks it up!
            --desired-dimension: 64px;
        }
    }
}

.certLogosWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacer(sm);

    @include breakpoint(md) {
        justify-content: flex-start;
        margin-bottom: spacer(2xl);
    }

    .certSprite {
        flex-shrink: 1;
        height: 64px;
        width: auto;

        @include breakpoint(md) {
            padding-right: 40px;
            height: 96px;
        }
    }
}

.disclaimer {
    @extend .t-bodySm;
    line-height: normal;
    font-style: italic;
    padding-bottom: spacer(2xs);
}

.callout {
    background: #F5EEE2;
    color: color(darkContrast, 3);
    line-height: normal;
    margin: spacer(md) 0;
    padding: spacer(xs) spacer(md);
    width: fit-content;
}
