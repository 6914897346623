$env: production;
@import '@/styles/_component-base.styles.scss';

.wrapper {
    text-align: left;
    overflow: visible;

    &.contrast1 {
        background-color: color(contrast, 1);
    }
    &.contrast2 {
        background-color: color(contrast, 2);
    }
}

.accordionSet {
    border-top: 1px solid color(contrast, 3);
    margin-top: 0;
}

.accordion {
    padding: 0;
    border-bottom: 1px solid color(contrast, 3);
    margin-bottom: 0;
    background-color: unset;
}

.accordionTitleContainer {
    padding-left: 0;
    padding-right: 0;
}

.accordionContentContainer {
    padding-left: 0;

    ul {
        margin-left: 0;
        margin-bottom: 0;
    }
}

.accordionContent {
    overflow: visible;
}

.disclaimer {
    font-size: set-fontSize(14);
    margin-bottom: spacer(xs);
}

.note {
    background: #F5EEE2;
    color: color(darkContrast, 3);
    line-height: normal;
    margin: spacer(md) 0;
    padding: spacer(xs) spacer(md);

    &.contrast1 {
        background-color: white;
    }
}

.columns {
    width: 100%;
}

.header {
    font-size: set-fontSize(20);
    color: color(text, headingDark);

    @include breakpoint(lg) {
        font-size: set-fontSize(24);
    }
}

.list-heading-typed-item {
    display: flex;
    justify-content: flex-start;
    gap: spacer(xs);
}

.listPrefixedHeadingTypedItem {
    display: flex;
    justify-content: flex-start;
    gap: spacer(xs);
}

.list-table-typed {
    flex-grow: 0;
    padding-left: spacer(sm);
}

.listItem {
    display: flex;
    list-style-type: disc;
    margin-bottom: spacer(sm);
    line-height: spacer(lg);
    page-break-inside: avoid;
    break-inside: avoid-column;

    &::before {
        content: "\2022";
        margin-right: spacer(xs);
    }
}

.listSVG {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    color: color(accent, green);
}

.prefixedSVG {
    margin-top: (spacer(2xs) + spacer(3xs)); // 6px
    margin-right: spacer(xs);
    min-width: 16px;
}

.flexWrapper {
    display: flex;
}

.tooltip {
    width: 20px;
    top: 4px;
    margin-left: spacer(3xs);
}

.inlineTooltip {
    display: inline;
    top: spacer(xs);
    margin-left: spacer(2xs);

    button {
        position: relative;
    }
}

.tooltipContent {
    top: spacer(2xl);
    width: 250px;
    max-width: none;
}

.certSprite {
    flex-shrink: 1;
    height: 64px;
    width: 96px;

    @include breakpoint(md) {
        height: 96px;
    }
}

.listItem:has(.certSprite) {
    &::before {
        content: "";
        margin-right: 0;
    }
}