$env: production;
@import '@/styles/component-base.styles.scss';

.supportSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    padding: 3rem 0;
    background-color: #fff;
    width: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        text-align: left;
        gap: 40px;
    }
}

.contentWrapper {
    width: 100%;
    justify-content: center;

    @include breakpoint(md) {
        display: flex;
        width: auto;
        flex-wrap: nowrap;
        flex: 1 1 auto;
        gap: 20px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #6b6257;
    width: 100%;
    height: 225px;

    @include breakpoint(md) {
        max-width: 210px;
        height: 210px;
        overflow: hidden;
    }

    @include breakpoint(lg) {
        max-width: 348px;
        height: 348px;
    }
}

.title {
    font-family: "Source Serif Pro";
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

    @include breakpoint(lg) {
        font-size: 32px;
    }

}

.subtitle {
    margin: 0.25rem 0 0 0;
    font-family: "Source Serif Pro";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    width: 85%;

    @include breakpoint(lg) {
        font-size: 24px;
        margin: 4px 0 0;
    }
}

.contactInfo {
    height: 20px;
    margin-top: 0.5rem;
    font-family: "Source Serif Pro";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: normal;
    color: #fff;

    a {
        color: inherit;
        text-decoration: none;
    }

    @media (min-width: 768px) {
        font-size: 16px;
    }

    @include breakpoint(lg) {
        font-size: 16px;
    }
}

.contactInfo .dot {
    margin: 0 8px;

    @include breakpoint(md) {
        margin: 0 0.4rem;
    }
}

.chatLink {
    cursor: pointer;
    transition: color 0.1s ease-in-out;

    &:hover,
    &:active {
        color: #ccc;
    }
}