$env: production;
@import '@/styles/_component-base.styles.scss';


.contentContainer {
    @include breakpoint(sm, max) {
        max-width: 330px;
        margin: auto;
        padding-top: spacer(lg);
    }

    @include breakpoint(md) {
        margin-top: auto;
        margin-bottom: auto;
    }

    &--paddingLeft {
        @include breakpoint(md) {
            padding-left: spacer(base);
        }

        @include breakpoint(lg) {
            padding-left: spacer(7xl);
        }
    }

    &--paddingRight {
        @include breakpoint(md) {
            padding-right: spacer(base);
        }

        @include breakpoint(lg) {
            padding-right: spacer(7xl);
        }
    }
}

.title {
    margin-bottom: spacer(lg);
    color: color(text, base);
    font-size: set-fontSize(24);

    @include breakpoint(md) {
        margin-bottom: spacer(base);
    }

    @include breakpoint(lg) {
        font-size: set-fontSize(32);
    }
}

.tanAccordion {
    background: color(contrast, 2)
}

.linkButton {
    text-decoration: underline;
    font-size: set-fontSize(16);
    font-weight: 300;
    cursor: pointer;
}

.articleLink {
    color: color(action, base);
    width: 30px;
    height: 30px;
    margin-right: spacer(2xs);
    vertical-align: bottom;
}

.discList {
    list-style-type: disc;
    padding-left: 1rem;
}
