$env: production;
@import '@/styles/_component-base.styles.scss';

.container {
    width: calc(min(950px, 100%));
}

.table {
    table-layout: fixed;
    width: 100%;
    transition: margin-bottom 1s ease-in;
}

.shownRows {
    transition: margin-bottom 5s ease-out;
    opacity: 1;
}

.shownRestRows {
    opacity: 0;
    display: none;
}

.isExpanded{
    animation: fadeIn ease-in-out 500ms;
}
  
  @keyframes fadeIn {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }

.featureCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: spacer(md);
}

.featureTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.featureTitle {
    text-transform: uppercase;
    letter-spacing: 4px;
    font-family: $fontFamily-sans-serif;
    color: color(text, base);
    font-size: set-fontSize(16);
}

.featureTitleDisclaimer {
    flex: 1 0 100%;
}

.featureValue {
    font-size: set-fontSize(32);;
    font-family: $fontFamily-serif;
    font-weight: normal;
}

.featureSubtextContainer {
    width: 100%;

    @include breakpoint(md) {
        max-width: 300px;
    }
}

.featureSubtext {
    font-family: $fontFamily-sans-serif;
    color: color(text, base);

    span {
        font-weight: 300;
    }
}

.details {
    color: color(text, headingDark);
}

.cellTitle {
    color: color(text, base);
    font-size: set-fontSize(28);
    font-weight: normal;
}

.list {
    text-align: left;
    list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='-2.2 -2.4 4 4'><circle r='1' fill='currentColor'/></svg>");
}

.textBase {
    color: color(text, base);
}

.btnToolTip {
    right: -10px;

    @include breakpoint(sm) {
        right: 0;
    }
}