$env: production;
@import '@/styles/_component-base.styles.scss';

$line-height: spacer(md);
$element-spacing: spacer(lg);

/* 
This first `sectionWrapper` block encapsulates Light/Dark mode settings.
Using `--my-var` variables allow for these to be set dynamically based on classes.
Unfortunately they must be concrete values, and can't use CSS helpers like `color(x, y)`.
The relevant pattern library colors are commented below.
*/
.sectionWrapper {
    --nav-color: #c19678;                   // color(action, base);
    --text-color: #fff;                     // color(contrast, white);
    --accordion-divider-color: #909090;     // color(text, hover);
    --accordion-text-color: #fff;           // color(contrast, white);
    background: linear-gradient(#000, #453F39);

    &.lightMode {
        --nav-color: #909090;               // color(text, hover);
        --text-color: #000;                 // color(text, base);
        --accordion-divider-color: #d2d2d2; // color(contrast, 3);
        --accordion-text-color: #000;       // color(text, base);
        background: linear-gradient(to bottom, #fff, #f6f5f3 99%);
    }
}

.sectionWrapper {
    color: var(--text-color);
    padding: spacer(3xl) spacer(md);
    text-align: left;

    @include breakpoint(md){
        padding: spacer(3xl);
    }

    @include breakpoint(lg) {
        padding: spacer(4xl) 0 0;
    }

    h2, h3, h4 {
        color: var(--text-color);
    }

    h2 {
        @extend .t-heading2;
        margin-bottom: 0;
        text-align: center;
    }
}

.content {
    color: var(--text-color);
    flex-grow: 1;

    > :first-child {
        margin-top: 0;
    }

    // Child combinator (`>`) is leveraged below to ensure we don't unintentionally style elements
    // within nested components. We are ONLY styling direct children of the `.content` element.

    > ul {
        list-style: unset;
        list-style-type: disc;
        margin-left: spacer(md);
        margin-bottom: spacer(sm);

        li {
            line-height: $line-height;
            margin-bottom: spacer(xs);
        }
    }

    > h3 {
        @extend .t-heading3;
    }

    > h4 {
        @extend .t-heading4;
        margin-bottom: spacer(sm);
        margin-top: $element-spacing;
        line-height: normal;
    }

    > p {
        line-height: $line-height;
        margin-bottom: spacer(sm);
    }

    > button, > a {
        width: 100%;
        margin-top: spacer(sm);

        @include breakpoint(md) {
            width: unset;
            padding-left: spacer(8xl);
            padding-right: spacer(8xl);
        }

        @include breakpoint(lg) {
            margin-top: spacer(md);
            padding-left: spacer(6xl);
            padding-right: spacer(6xl);
        }
    }

    strong {
        @extend .t-base
    }

    small {
        @extend .t-bodySm;
        line-height: inherit;
    }

    em {
        font-style: italic;
    }

    // Special case div, only used to wrap CTA buttons for proper placement
    > div {
        margin-top: $element-spacing;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;

        @include breakpoint(sm) {
            flex-direction: row;
        }

        // This is necessary as row orientation of two buttons in small desktop sizes
        // breaks the layout in a window between `lg` (1200px) and 1340px
        @include breakpoint(lg) {
            flex-direction: column;
        }

        @include breakpoint(1340px) {
            flex-direction: row;
        }

        a {
            flex-grow: 1;
        }
    }
}

.tabbedFeatures {
    &__nav {
        display: flex;
        justify-content: center;
        gap: spacer(2dot5xl);
        border-bottom: 1px solid var(--nav-color);
        margin-bottom: spacer(3xl);
    }

    &__navItem {
        color: var(--nav-color);
        margin-bottom: 0;
        font-size: set-fontSize(18);
        padding: $element-spacing 0;
        border: unset;
        min-width: unset;

        &--active {
            color: var(--text-color);
        
            // White underline for active element
            &::before{ 
                content: '';
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                height: 2px;
                width: 100%;
                background-color: var(--text-color);
            }
        }
    }

    &__contentWrapper {
        display: none;
        gap: spacer(2xl);
        padding-bottom: spacer(4xl);

        &--leftImage {
            flex-direction: row-reverse;
        }

        &--active{
            display: flex;
        }
    }
}

.accordionFeatures {
    border-bottom: 1px solid var(--accordion-divider-color);

    &__contentWrapper {
        margin-top: spacer(xs);

        .content {
            margin-top: $element-spacing;
            margin-bottom: 0;
        }

        .image{
            img {
                width: 100%;
            }
        }
    }
    

    .accordionItem {
        background: transparent;
        color: var(--accordion-text-color);
        border-top: 1px solid var(--accordion-divider-color);
        padding-top: spacer(xs);
        margin-bottom: spacer(xs) !important; // override the accordion margin in the bit component

        svg {
            stroke: var(--text-color);
            stroke-width: 1;
        }

        &__title {
            color: var(--accordion-text-color);
            font-size: set-fontSize(18);
        }

        &__titleContainer {
            padding: spacer(base) 0;
        }

        &__contentContainer {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: spacer(base);
        }
    }
}
